<template>
  <v-row no-gutters>
        <v-col >
            <div style="width:46px ; float:left">
              <v-btn outlined icon x-large @click="back()">
                <v-icon> {{ icons.mdiChevronLeft }}</v-icon>
            </v-btn>
            </div>
            <div style="float:left" class="pt-3 ml-2">{{label}}</div>
        </v-col>
    </v-row>
</template>
<script>

import {
    mdiChevronLeft
} from '@mdi/js'

export default {
    props:{
      label:{type:String,default:'Voltar'}
    },
    data: () => ({
        icons: {
            mdiChevronLeft
        },
    }),
    methods: {
        back(){
          this.$emit('back')
        },
    },
}
</script>
