<template>
<v-container>

     <BtnBack @back="back()"></BtnBack>
    <v-card elevation="2" class="mt-4">
        <v-toolbar elevation="0">
            <v-toolbar-title>Caixa</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field v-model="search" :append-icon="icons.mdiMagnify" single-line label="Localizar" class="" outlined filled rounded dense hide-details @click:append="" @change=""></v-text-field>
            <!-- <InputDate css="mt-7 ml-2 col-2" ></InputDate> -->
            <v-spacer></v-spacer>


        </v-toolbar>
        <v-card elevation="0" >
            <v-card-text>
                <v-row no-gutters>
                    <v-col>
                        <h4>Saldo Inicial: <span class=""> R$ {{ formatMoney(extract.initial_cash||0)}} </span></h4>
                    </v-col>
                    <v-col>
                        <h4>Entradas: <span class="signal_positive"> R$ {{ formatMoney(extract.input)}} </span></h4>
                    </v-col>
                    <v-col>
                        <h4>Saidas: <span class="signal_negative"> R$ {{ formatMoney(extract.output)}} </span></h4>
                    </v-col>
                    <v-col>
                        <h4>Saldo: {{ formatMoney(extract.balance) }}</h4>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn v-if="extract.obs" @click="dialogObs = true" rounded color="primary">Observações</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
<v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>
        <v-simple-table dense >
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>DATA</th>
                        <th>CATEGORIA</th>
                        <th>DESCRIÇÃO</th>
                        <th>CLIENTE/FORNECEDOR</th>
                        <!-- <th>Conta</th> -->
                        <th>VALOR</th>
                    </tr>
                </thead>
                <tbody v-if="extract.data.length > 0">
                    <tr v-for="(item,key) in extract.data" :key="key">
                        <td>{{ formatDateTime(item.date) }}</td>
                        <td>{{ item.category }}</td>
                        <td>{{ item.description  }}</td>
                        <td>{{ item.customer_supplier }}</td>
                        <!-- <td>{{ }}</td> -->
                        <td  class="text-right" ><b><i   :class="{signal_negative:(item.signal=='-'),signal_positive:(item.signal=='+')}">{{ formatMoney(item.value) }}</i></b></td>
                    </tr>
                </tbody>
                <!-- No data -->
                <tbody v-else>
                  <tr>
                    <td colspan="5" class="text-center">
                      <NoDataTable></NoDataTable>
                    </td>
                  </tr>
                </tbody>
            </template>

        </v-simple-table>
    </v-card>

    <v-dialog v-model="dialogObs" width="600" >
      <v-card>
        <BarTitleDialog title="Observação" @close="dialogObs = false" ></BarTitleDialog>
        <v-card-text>
            <p>{{extract.obs}}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
</v-container>
</template>

<script>

import {
    mdiMagnify,
    mdiPlus,
    mdiChevronLeft
} from '@mdi/js'


import {
    mapActions,
    mapMutations
} from 'vuex'

import NoDataTable from '@/components/NoDataTable.vue'
import BtnBack from '@/components/BtnBack.vue'

import BarTitleDialog from '@/components/BarTitleDialog.vue'
export default {
    components: {
        mdiPlus,
        mdiMagnify,
        NoDataTable,
        BtnBack,
        BarTitleDialog,
    },
    data: () => ({
        dialogObs:false,
        extract: [],
        showModalWithdrawal: false,
        showModalDeposit: false,
        search: '',
        loading:false,
        icons: {
            mdiMagnify,
            mdiPlus,
            mdiChevronLeft
        },
        // cash: [
        //   {id:1}
        // ],
    }),

    created() {
        this.loading = true
        this.getExtract(this.$route.params.cash).then((response) => {

            this.extract = response.data
        }).finally(()=>{
          this.loading = false
        })
    },
    watch: {

    },
    computed: {

    },

    methods: {
        ...mapActions('cash_list', [, 'getExtract']),
        ...mapMutations('cash_list', ['setCash']),


        back(){
          this.$router.push('/cash_list')
        },

        formatMoney(value) {
            value = parseFloat(value)
            if (value != null) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
        },

        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        formatDateTime(date) {
            if (date) {
                const result = new Date(date);
                return result.toLocaleDateString('pt-BR', {
                    timeZone: 'America/Sao_Paulo'
                }) + " " + result.toLocaleTimeString('pt-BR')
            }

        },

    },
}
</script>

<style scoped>
.signal_negative {
    color: #ff4c51
}

.signal_positive {
    color: #56ca00

}
</style>
